import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledSmallGallery = styled.div`
  width: 100%;

  .swiper-pagination-bullet {
    width: ${Rem(14)};
    height: ${Rem(14)};
    margin: 0 ${Rem(8)} !important;
    background-color: ${Colors.gray};
    opacity: 1;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.white};
  }
`;

export const StyledSmallGalleryItems = styled.div``;

export const StyledSmallGalleryItem = styled.div``;

export const StyledSmallGalleryItemImage = styled.div`
  position: relative;
  ${Ratio(71, 55)};
  background-size: cover;
  background-position: center;
  border-radius: ${Rem(10)};
  max-width: ${Rem(400)};
  margin: 0 auto;
  
  @media(${BP.ipad}) {
    ${Ratio(384, 265)};
    width: 100%;
  }
`;
