import React, { useEffect, useRef } from "react";
import {
  StyledSmallGallery,
  StyledSmallGalleryItem,
  StyledSmallGalleryItemImage,
  StyledSmallGalleryItems,
} from "./style";
import Swiper, { Pagination } from "swiper";

function disabledEventPropagation(event) {
  if (event.stopPropagation) {
    event.stopPropagation();
  }
  else if (window.event) {
    window.event.cancelBubble = true;
  }
}

Swiper.use([Pagination]);
export default ({ items }) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();

  useEffect(() => {
    if (sliderEl) {
      const slider = new Swiper(sliderEl.current, {
        allowTouchMove: "ontouchstart" in window,
        observer: true,
        observeParents: true,
        autoHeight: true,
        slidesPerView: 1,
        pagination: {
          el: sliderPaginationEl.current,
        },
      });

      Object.values(sliderPaginationEl.current.children).map((item, index) =>
        item.addEventListener("click", (e) => {
          disabledEventPropagation(e);
          e.preventDefault();
          slider.slideTo(index);
          return false;
        }),
      );
    }
  }, [sliderEl]);

  return (
    <StyledSmallGallery>
      <StyledSmallGalleryItems ref={sliderEl} className="swiper-container">
        <div className="swiper-wrapper">
          {items.map((item, index) => (
            <StyledSmallGalleryItem key={index} className="swiper-slide">
              <StyledSmallGalleryItemImage style={{ backgroundImage: `url(${item})` }}/>
            </StyledSmallGalleryItem>
          ))}
        </div>
        <div className="swiper-pagination" ref={sliderPaginationEl} />
      </StyledSmallGalleryItems>
    </StyledSmallGallery>
  );
};
